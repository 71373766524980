import { SubscriptionInterval, SubscriptionLevel, SubscriptionPointAmount } from '../../../enums'

export function getPoints(subLevel: SubscriptionLevel, interval: SubscriptionInterval): number {
  switch (true) {
    case interval === SubscriptionInterval.Weekly && subLevel === SubscriptionLevel.Plus:
      return SubscriptionPointAmount.PlusWeekly
    case interval === SubscriptionInterval.Weekly && subLevel === SubscriptionLevel.Infinite:
      return SubscriptionPointAmount.InfiniteWeekly
    case subLevel === SubscriptionLevel.Plus:
      return SubscriptionPointAmount.Plus
    case subLevel === SubscriptionLevel.Infinite:
      return SubscriptionPointAmount.Infinite
    default:
      return SubscriptionPointAmount.Plus
  }
}

export const calculateAmountPerWeek = (amount: number, interval: SubscriptionInterval) => {
  let result = amount
  switch (interval) {
    case SubscriptionInterval.Monthly:
      result = amount / 4
      break
    case SubscriptionInterval.SixMonths:
      result = amount / 6 / 4
      break
    default:
      break
  }
  return Math.trunc(Math.floor(result * 100) / 100)
}

export const DEVICE_NAME = {
  ANDROID: 'Android Password Manager',
  IOS: 'iCloud Keychain',
  WINDOWS: 'Windows Hello',
  GOOGLE: 'Google Password Manager',
  OTHER: 'Other',
}

export const getDeviceName = () => {
  const { userAgent } = navigator
  if (/android/i.test(userAgent)) {
    return DEVICE_NAME.ANDROID
  }
  if (/iPad|iPhone|iPod|Macintosh/.test(userAgent)) {
    return DEVICE_NAME.IOS
  }
  if (/Windows/.test(userAgent)) {
    return DEVICE_NAME.WINDOWS
  }
  return DEVICE_NAME.OTHER
}

export const isIOS = () => {
  const { userAgent } = navigator
  return /iPad|iPhone|iPod/.test(userAgent)
}
