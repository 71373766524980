import { createSelector } from 'reselect'

export const layoutState = (state) => state.layout

export const selectLayoutState = createSelector([layoutState], (state) => state)

export const selectDialogs = createSelector([layoutState], (state) => state.dialogs)

export const selectSubscriptionInterval = createSelector(
  [layoutState],
  (state) => state.subscriptionInterval,
)
