import React, { useState, useEffect } from 'react'
import qs from 'querystring'
import { useHistory, useLocation } from 'react-router'
import map from 'lodash/fp/map'
import { useDispatch } from 'react-redux'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/styles'
import { MenuItem, Select, InputBase, Theme } from '@material-ui/core'
import { useCookies } from 'react-cookie'

import { setLocale } from '../../store/app/actions'
import { languages, DEFAULT_LANG, languageKeys } from '../../utils/i18n'

const useStyles = makeStyles((theme: Theme) => ({
  select: {
    color: theme.palette.common.white,
    minWidth: 120,
    marginLeft: 20,
    marginRight: 20,
    '&:before': {
      borderColor: `${theme.palette.common.white} !important`,
    },
    '& .MuiSelect-icon': {
      color: theme.palette.common.white,
    },
    '& .MuiSelect-select': {
      borderBottom: `1px solid ${theme.palette.common.white}`,
      color: theme.palette.common.white,
      paddingLeft: 7,
      transition: theme.transitions.create(['border-width']),
      '&:focus': {
        backgroundColor: 'rgba(255, 255, 255, .17)',
      },
    },
  },
}))

const LanguageSelect = () => {
  const history = useHistory()
  const { query, pathname } = useLocation()
  const dispatch = useDispatch()
  const { i18n } = useTranslation()
  const classes = useStyles()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookies] = useCookies()
  const isSupportLang = languageKeys.includes(i18n.language)
  const [lang, setLang] = useState(!i18n.language || !isSupportLang ? DEFAULT_LANG : i18n.language)

  const handleChange = async ({ target }) => {
    dispatch(setLocale(target.value))
    i18next.changeLanguage(target.value)
    setCookies('lang', target.value, { path: '/' })
    if (query && query.lang) {
      query.lang = target.value
      history.replace({
        pathname,
        search: qs.stringify(query),
      })
    }
  }

  useEffect(() => {
    setLang(!i18n.language || !isSupportLang ? DEFAULT_LANG : i18n.language)
  }, [i18n.language, isSupportLang, query])

  return (
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      input={<InputBase className={classes.select} />}
      value={lang}
      onChange={handleChange}
    >
      {map(({ name, short }) => (
        <MenuItem key={short} value={short}>
          {name}
        </MenuItem>
      ))(languages)}
    </Select>
  )
}

export default LanguageSelect
