import React from 'react'
import cn from 'classnames'
import { AppBar, Theme, Toolbar, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

// utils
// actions
// Components
import Logo from './Logo'

// Material UI
const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    ...theme.mixins.toolbar,
  },
  flexOccupy: {
    display: 'flex',
    alignItems: 'center',
    flex: '0 0 auto',
    maxWidth: '40%',
  },
  toolbar: {
    ...theme.mixins.toolbar,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  menuButton: {
    fontSize: 30,
  },
  btnLeft: {
    marginLeft: -12,
    marginRight: 12,
  },
  btnRight: {
    marginRight: -12,
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      maxWidth: theme.spacing(6),
    },
  },
  title: {
    flex: '1 1 auto',
    overflow: 'hidden',
    marginRight: theme.spacing(2),
    whiteSpace: 'nowrap',
    '& h2': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  leftBlock: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: `calc(100% - ${theme.spacing(6)}px)`,
    },
  },
}))

const HeaderAppBar = () => {
  const classes = useStyles()

  return (
    <div className={classes.appBar}>
      <AppBar key="header-bar" position="fixed" color="primary">
        <Toolbar className={classes.toolbar}>
          <Typography component="div" className={cn([classes.leftBlock, classes.flexOccupy])}>
            <div id="portal-title" className={classes.title} />
          </Typography>
          <Logo />
          <div id="portal-buttons" className={cn([classes.btnRight, classes.flexOccupy])} />
        </Toolbar>
      </AppBar>
    </div>
  )
}

HeaderAppBar.whyDidYouRender = true

export default HeaderAppBar
