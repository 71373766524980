import React, { useEffect, useState } from 'react'
import { IconButton } from '@material-ui/core'
import BrightnessHighIcon from '@material-ui/icons/BrightnessHigh'
import Brightness4Icon from '@material-ui/icons/Brightness4'
import { makeStyles } from '@material-ui/styles'
import { useCookies } from 'react-cookie'

const useStyles = makeStyles(() => ({
  switchThemeButton: {
    height: '48px',
    alignSelf: 'center',
    borderRadius: '50%',
    overflow: 'hidden',
  },
  rotate: {
    transform: 'rotate(360deg)',
    transition: '0.6s',
    color: '#fff',
  },
  rotateReverse: {
    transform: 'rotate(-360deg)',
    transition: '0.6s',
    color: '#fff',
  },
}))

const SwitchThemeButton = () => {
  const [cookies, setCookies] = useCookies()
  const classes = useStyles()
  const [canChangeTheme, setCanChangeTheme] = useState(true)

  // The transition of switching animation by 0.6s so users need to wait exactly 600ms
  // to wait the animation end and continue click to button to switch theme
  useEffect(() => {
    const timeoutChangeTheme = setTimeout(() => {
      setCanChangeTheme(true)
    }, 600)
    return () => clearTimeout(timeoutChangeTheme)
  }, [canChangeTheme])

  const handleChangeTheme = () => {
    if (canChangeTheme) {
      const newTheme = cookies.theme === 'white' ? 'black' : 'white'
      setCookies('theme', newTheme, { path: '/' })
      setCanChangeTheme(false)
    }
  }

  return (
    <IconButton
      className={`${cookies.theme === 'white' ? classes.rotate : classes.rotateReverse} ${
        classes.switchThemeButton
      }`}
      color="inherit"
      onClick={handleChangeTheme}
    >
      {cookies.theme === 'white' ? <Brightness4Icon /> : <BrightnessHighIcon />}
    </IconButton>
  )
}

export default SwitchThemeButton
