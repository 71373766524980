import ACTIONS from './constants'

export const openPasskeyDialog = (data) => ({
  type: ACTIONS.OPEN_PASSKEY_DIALOG,
  data,
})

export const closePasskeyDialog = (data) => ({
  type: ACTIONS.CLOSE_PASSKEY_DIALOG,
  data,
})

export const setPasskeyDialogState = (data) => ({
  type: ACTIONS.SET_PASSKEY_DIALOG_STATE,
  data,
})
