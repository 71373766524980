import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
// Material UI
import { Button, Snackbar, SnackbarContent, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

// Actions
import { closeNotification, resetNotification } from '../../store/notification/actions'
import { selectNotify } from '../../store/notification/selectors'

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  error: {
    backgroundColor: theme.palette.error.dark,
  },
}))

const Notification = () => {
  const classes = useStyles()
  const { btn, btnTitle, duration, message, messageCode, messageType, type, visible } = useSelector(
    selectNotify,
  )
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(closeNotification())
  }

  const handleExited = () => {
    dispatch(resetNotification())
  }

  return (
    <Snackbar
      data-tes="alert"
      open={visible}
      autoHideDuration={duration}
      onClose={handleClose}
      onExited={handleExited}
    >
      <SnackbarContent
        className={classes[type]}
        message={messageType === 'code' ? t(message) : message || messageCode}
        action={
          btnTitle && (
            <Button
              onClick={() => {
                btn()
                dispatch(closeNotification())
              }}
              size="small"
            >
              {btnTitle}
            </Button>
          )
        }
      />
    </Snackbar>
  )
}

export default Notification
