/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react'
import cn from 'classnames'
// Material UI
import { CircularProgress, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: (props: any) => props.height,
    width: '100%',
    backgroundColor: theme.palette.colors?.white,
  },
}))

const Loader = ({
  classNames,
  height = '100%',
  haveLoading = true,
  ...props
}: {
  classNames?: string
  height?: string
  haveLoading?: boolean
  [propsName: string]: any
}) => {
  const classes = useStyles({ height })

  return (
    <div className={cn(classes.root, classNames)}>
      {haveLoading && (
        <CircularProgress {...props} style={{ background: 'transparent !important' }} />
      )}
    </div>
  )
}

Loader.whyDidYouRender = true

export default memo(Loader)
