import { createSelector } from 'reselect'

export const app = (state) => state.app
export const authState = (state) => state.auth
export const formState = (state) => state.form

export const getLocale = createSelector([app], (appState) => appState?.locale)

export const selectCurrentUser = createSelector([authState], (state) => state?.currentUser)

export const selectLoadingCurrentUser = createSelector([authState], (state) => state?.loading)

export const selectFormState = (formName: string) =>
  createSelector([formState], (state) => state?.[formName])
